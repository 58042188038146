(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("axios"), require("semantic-ui-react"), require("lodash"), require("react-sortable-tree"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "axios", "semantic-ui-react", "lodash", "react-sortable-tree", "moment"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("axios"), require("semantic-ui-react"), require("lodash"), require("react-sortable-tree"), require("moment")) : factory(root["react"], root["axios"], root["semantic-ui-react"], root["lodash"], root["react-sortable-tree"], root["moment"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 